import { createStore } from 'vuex'

export default createStore({
  state: {
    popup: false,
    veso: 1
  },
  getters: {
  },
  mutations: {
    POPUP_MUTAION(state, value){
        state.popup = value
    },
    CHON_VE_MUTATION(state, value){
      state.veso = value
    }
  },
  actions: {
    popup_action({commit}, value){
        commit("POPUP_MUTAION", value)
    },
    chonve_action({commit}, value){
      commit('CHON_VE_MUTATION', value)
    }
  },
  modules: {

  }
})

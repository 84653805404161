<template lang="">
  <div class="slider" :class="{ active: statePopup }">
    <div class="slider-content">
      <swiper
        :slides-per-view="6"
        :space-between="20"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(ve, index) in vexeArr" :key="index" @click="chonve(index+1)" :class="{active: getve == index+1}">
          <img :src="ve" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="slider-close" @click="hiddenPopup">
      <i class="bx bx-x"></i>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import vexe from "@/assets/vexe.jpg";
import vexe02 from "@/assets/vexe02.jpg";
import "swiper/css";
import "swiper/css/navigation";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation],
      vexeArr: [vexe, vexe02],
    };
  },
  computed: {
    ...mapState(["popup", "veso"]),
    statePopup() {
      return this.popup;
    },
    getve(){
      return this.veso
    }
  },
  methods: {
    ...mapActions(["popup_action", "chonve_action"]),
    hiddenPopup() {
      this.popup_action(false);
    },
    chonve(value){
      this.chonve_action(value)
      this.hiddenPopup()
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/index.scss";
.mySwiper {
  height: 100%;
  overflow: hidden;
  padding: 20px 60px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  // .swiper-slide-active {
  //   border: 3px solid #1aafa0;
  // }
  .swiper-slide.active{
    border: 3px solid #1aafa0;
  }
}
.swiper-button-next,
.swiper-button-prev {
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 999px;
}

.slider {
  height: 200px;
  width: 100%;
  position: fixed;
  top: $header-height;
  left: 0;
  z-index: 999;
  visibility: hidden;
  transform: translateY(-130%);
  transition: all 0.2s linear;
  background: rgba(34, 49, 63, 0.8);

  &.active {
    transform: translateY(0);
    transition: all 0.2s linear;
    visibility: visible;
  }

  &-content {
    height: 100%;
  }

  &-close {
    width: 50px;
    height: 50px;
    border-radius: 999px;
    background: rgba(34, 49, 63, 0.8);
    position: absolute;
    left: 50%;
    bottom: 30px;
    @include flex(center, center);
    color: $txt-white;
    font-size: 20px;
    transform: translateX(-50%);
    visibility: hidden;
    cursor: pointer;
    transition: all 0.6s linear;

    &:hover {
      animation: fun 2s infinite;
      color: #1aafa0;
    }
  }
}

.slider.active .slider-close {
  bottom: -100px;
  transition: all 0.6s linear;
  visibility: visible;
}

@keyframes fun {
  from {
    transform: translate(-50%, 0px) scale(1.2);
  }
  to {
    transform: translate(-50%, 0px) scale(1);
  }
}
</style>

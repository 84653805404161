import { createRouter, createWebHistory } from 'vue-router'
import homeTemplate from "@/template/HomeTemplate/index.vue"
import homeView from "@/views/homeview/index.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeTemplate,
    children: [
      {
      path: '/',
      name: 'homeview',
        component: homeView
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template lang="">
  <div>
    <header-app-vue></header-app-vue>
    <router-view></router-view>
  </div>
</template>
<script>
import HeaderAppVue from "@/components/header/HeaderApp.vue";
export default {
  components: {
    HeaderAppVue,
  },
};
</script>
<style lang=""></style>

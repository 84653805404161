<template lang="">
    <div class="header">
       <div class="header-logo">
            <img :src="logo" alt="">
       </div>
       <navbar-app-vue />
    </div>
</template>
<script>
import NavbarAppVue from "@/components/navbar/NavbarApp.vue";
import logo from "@/assets/logo.png";
export default {
  components: {
    NavbarAppVue,
  },
  data() {
    return {
      logo,
    };
  },
};
</script>
<style lang="scss">
@import "@/scss/index.scss";
.header {
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $main-bg;
  z-index: 999;
  box-shadow: $box-shadow;
  &-logo {
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
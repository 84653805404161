<template lang="">
<slider-app-vue></slider-app-vue>
  <div class="home">
    
    <div class="home-left">
      
      <Vexe :vexe="vexe" v-if="getVeso == 1"/>
      <Vexe02 :vexe="vexe" v-if="getVeso == 2"/>
    </div>
    <div class="home-right">
      <div class="home-content">
        <ul class="home-content-list">
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Tên doanh nghiệp (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.tencongty"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <input type="file" name="" ref="image" hidden value="" @change="changeImage" />
            <DxButton
              text="Chọn Logo"
              type="success"
              width="100%"
              styling-mode="contained"
              icon="bx bx-image-alt"
              @click="chooseImage"
            />
          </li>

          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Địa chỉ (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.diachi"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Số điện thoại (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.sdt"
              v-if="getVeso == 1"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="SĐT Phòng vé đi (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.sdtpvdi"
              v-if="getVeso == 2"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="SĐT Phòng vé đến (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.sdtpvden"
              v-if="getVeso == 2"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Mã số thuế (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.mst"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Ký hiệu (Serial)(*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.kyhieu"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Số (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.so"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Tên doanh nghiệp (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.tendoanhnghiep"
              v-if="getVeso == 2"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxDateBox
              :value="now"
              label="Thời gian (*)"
              stylingMode="outlined"
              type="datetime"
              labelMode="floating"
              v-model="vexe.ngaythang"
            />
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Điểm đi (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.diemdi"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Điểm đến(*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.diemden"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Giá vé(*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.gia"
            >
            </DxTextBox>
          </li>
           <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Chú thích (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.chuthich"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Số điện thoại hành khách(*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.sdthk"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Biển số xe (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.bienso"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="Số ghế (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.soghe"
            >
            </DxTextBox>
          </li>
          <li class="home-content-item">
            <DxTextBox
              stylingMode="outlined"
              label="MCCQT (*)"
              labelMode="floating"
              validationMessageMode="always"
              v-model="vexe.mccqt"
            >
            </DxTextBox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxTextBox from "devextreme-vue/text-box";
import DxDateBox from "devextreme-vue/date-box";
import DxButton from "devextreme-vue/button";
import Vexe from "@/components/vexe/Vexe.vue";
import SliderAppVue from "@/components/silder/SliderApp.vue";
import Vexe02 from "@/components/vexe02/Vexe.vue"
import { mapState } from 'vuex';
export default {
  components: {
    
    DxTextBox,
    DxDateBox,
    DxFileUploader,
    DxButton,
    Vexe,
    SliderAppVue,
    Vexe02
  },
  data() {
    return {
      vexe: {
        tencongty: "HTX VẬN TẢI DV VÀ DL SÀI GÒN",
        diachi: "Số 175 Quốc Lộ 13, P.26, Q.Bình Thạnh, TP.HCM",
        sdt: "(028) 38991602 - 38984434",
        mst: "0302506177",
        kyhieu: "5C22GDD",
        so: "00001901",
        tendoanhnghiep: "PHI HIỆP",
        ngaythang: new Date(),
        diemdi: "DIEM A",
        diemden: "DIEM B",
        gia: "600000",
        sdthk: "0982910005",
        bienso: "29C 50431",
        soghe: "09",
        mccqt: "003211DF34AA2F41058A60513FCCA8EAD3",
        logo: '',
        sdtpvdi: "(028) 38991602",
        sdtpvden: "(028) 38991602",
        chuthich: "Giá trên đã bao gồm thuế và BHHK"
      },
    };
  },
  computed: {
    ...mapState(['veso']),
    getVeso(){
      return this.veso
    }
  },
  methods: {
    chooseImage() {
      this.$refs.image.click();
    },
    changeImage(e) {
      let _this = this;
      let files = e.target.files || e.dataTransfer.files;
      let checkTypeImage =
        files[0].type == "image/jpeg" || files[0].type == "image/png";
      if (checkTypeImage) {
        let url = URL.createObjectURL(files[0]);
        this.vexe.logo = url;
      } else {
        alert("file khong hop le");
      }
    },

  },
};
</script>
<style lang="scss">
@import "@/scss/index.scss";
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(192, 188, 188);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.home {
  @include flex(flex-start, space-between);
  flex-direction: row-reverse;

  &-left {
    width: 80%;
  }

  &-right {
    flex-grow: 1;
    background: $bg-input;
    overflow-y: scroll;
    height: 100vh;
    padding: 20px 0;
  }

  &-content {
    padding: 50px 20px;

    &-item {
      margin-top: 30px;
    }
  }
}
</style>

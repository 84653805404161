const AutoFocusErrorHandlers = [
  (wrapper) => {
    let input = undefined;
    if ((input = wrapper.querySelector("input.dx-texteditor-input"))) {
      input.focus();
      return true;
    }
  },
  (wrapper) => {
    let input = wrapper.querySelector("input,text-area,select");
    if (input) {
      input.focus();
      return true;
    }
  },
];

export default function Helper() {
  this.autoFocusError = (result) => {
    if (
      result.brokenRules &&
      result.brokenRules.length &&
      result.brokenRules[0].validator &&
      result.brokenRules[0].validator._$element &&
      result.brokenRules[0].validator._$element.length
    ) {
      let wrapper = result.brokenRules[0].validator._$element[0];

      for (let AutoFocusErrorHandler of AutoFocusErrorHandlers) {
        if (AutoFocusErrorHandler && AutoFocusErrorHandler(wrapper)) {
          break;
        }
      }
    }
  };

  this.formatDateTime = (dateTime) => {
    if (dateTime) {
      var tmp = new Date(dateTime);
      var HH = tmp.getHours();
      var mm = tmp.getMinutes();
      var dd = tmp.getDate();
      var MM = tmp.getMonth() + 1;
      HH < 10 ? (HH = "0" + HH) : HH;
      mm < 10 ? (mm = "0" + mm) : mm;
      dd < 10 ? (dd = "0" + dd) : dd;
      MM < 10 ? (MM = "0" + MM) : MM;
      var yyyy = tmp.getFullYear();
      return HH + ":" + mm + " " + dd + "/" + MM + "/" + yyyy;
    } else {
      return "";
    }
  };

  this.formatDate = (dateTime) => {
    if (dateTime) {
      var tmp = new Date(dateTime);
      var dd = tmp.getDate();
      var MM = tmp.getMonth() + 1;
      dd < 10 ? (dd = "0" + dd) : dd;
      MM < 10 ? (MM = "0" + MM) : MM;
      var yyyy = tmp.getFullYear();
      return dd + "/" + MM + "/" + yyyy;
    } else {
      return "";
    }
  };
  this.formatTime = (dateTime) => {
    if (dateTime) {
      var tmp = new Date(dateTime);
      var HH = tmp.getHours();
      var mm = tmp.getMinutes();
      HH < 10 ? (HH = "0" + HH) : HH;
      mm < 10 ? (mm = "0" + mm) : mm;
      return HH + ":" + mm;
    } else {
      return "";
    }
  };
  this.getToday = () => {
    let date = new Date();
    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0);
    return new Date(utc);
  };
  this.getEndDateString = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    let utc = Date.UTC(
      tmpDate.getFullYear(),
      tmpDate.getMonth(),
      tmpDate.getDate(),
      16,
      59,
      59
    );
    return `${new Date(utc).toISOString()}`;
  };

  this.getEndDay = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    return new Date(
      tmpDate.getFullYear(),
      tmpDate.getMonth(),
      tmpDate.getDate(),
      23,
      59,
      59
    );
  };

  this.getStartDay = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    return new Date(
      tmpDate.getFullYear(),
      tmpDate.getMonth(),
      tmpDate.getDate(),
      0
    );
  };
  this.getTimeString = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    let HH = tmpDate.getHours();
    let mm = tmpDate.getMinutes();
    HH = HH < 10 ? "0" + HH : HH;
    mm = mm < 10 ? "0" + mm : mm;
    return `${HH}:${mm}`;
  };
  this.getEndMonth = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    return new Date(
      tmpDate.getFullYear(),
      tmpDate.getMonth() + 1,
      0,
      23,
      59,
      59
    );
  };

  this.getStartMonth = (date) => {
    let tmpDate = date ? new Date(date) : new Date();
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1, 0);
  };

  this.formatDateToTimeZone7 = (tmpDate) => {
    let date = new Date(tmpDate);
    // let timezone = -date.getTimezoneOffset() / 60;
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let hourTimeZone = hour - 7;
    return new Date(
      Date.UTC(year, month, day, hourTimeZone, minutes, 0)
    ).toISOString();
  };

  this.formatViewDateToTimeZone7 = (tmpDate) => {
    let date = new Date(tmpDate);
    let timezone = -date.getTimezoneOffset() / 60;
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let hourTimeZone = hour - timezone - (timezone - 7);
    return new Date(
      Date.UTC(year, month, day, hourTimeZone, minutes, 0)
    ).toISOString();
  };

  this.CheckRole = (type, key) => {
    if (!key) return true;
    let keycloak = $Core.Auth;
    let DanhSachQuyen =
      keycloak.TokenParsed &&
      keycloak.TokenParsed.resource_access &&
      keycloak.TokenParsed.resource_access[type] &&
      keycloak.TokenParsed.resource_access[type].roles
        ? keycloak.TokenParsed.resource_access[type].roles
        : [];
    return DanhSachQuyen.some((e) => e == key);
  };

  this.randomGUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  this.formatPhone = (phoneNumber) => {
    let count = 0;
    let time = 0;
    let str = "";
    let tmpPhone = phoneNumber + "";
    tmpPhone = tmpPhone.trim();
    tmpPhone = tmpPhone.replaceAll(" ", "");
    if (tmpPhone.length < 9) {
      return tmpPhone;
    } else {
      for (let i = tmpPhone.length; i > 0; i--) {
        count++;
        if (count % 3 == 0 && time < 2) {
          time++;
          str = " " + tmpPhone[i - 1] + str;
        } else {
          str = tmpPhone[i - 1] + str;
        }
      }
      return str;
    }
  };

  this.moneyFormat = (number, decimals, dec_point, thousands_sep) => {
    var n = number,
      c = isNaN((decimals = Math.abs(decimals))) ? 2 : decimals;
    var d = dec_point == undefined ? "," : dec_point;
    var t = thousands_sep == undefined ? "," : thousands_sep,
      s = n < 0 ? "-" : "";
    var i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t)
    );
  };
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Helper from "./utils/Helper";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "@/scss/index.scss";

const $Helper = new Helper();
console.log($Helper);
createApp(App)
  .use(store)
  .use(router)
  .use({
    install(App) {
        let prototype = App.config.globalProperties;
        function protoDefProp(propName, getter, setter) {
        let propMap = {};
        getter && (propMap.get = getter);
        setter && (propMap.get = setter);
        Object.defineProperty(prototype, propName, propMap);
      }
      protoDefProp("$Helper", () => $Helper);
    },
  })
  .mount("#app");

<template lang="">
  <div class="navbar">
    <ul class="navbar-list">
      <li class="navbar-item" @click="showPopup">
        <i class="bx bxs-sticker"></i>
        Mẫu vé
      </li>
      <li class="navbar-item">
        <button type="" class="btn" @click="makePDF">
          <i class="bx bx-down-arrow-alt"></i>
          Tải xuống
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["popup_action"]),
    makePDF() {
      // let element = document.getElementById("vexe");
      // var opt = {
      //   margin: 1,
      //   filename: "myfile.pdf",
      //   image: { type: "jpeg", quality: 1 },
      //   html2canvas: { scale: 1 },
      //   jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      // };

      // // New Promise-based usage:
      // html2pdf().from(element).set(opt).save("vexe.pdf");
      // return;
      let element = document.getElementById("vexe");
      element.style.boxShadow = "unset";
      html2canvas(element).then((canvas) => {
        const base64image = canvas.toDataURL("image/jpg");
        let anchor = document.createElement("a");
        anchor.setAttribute("href", base64image);
        anchor.setAttribute("download", "vexe.jpg");
        anchor.click();
        anchor.remove();
        element.style.boxShadow = "0 1px 9px rgba(0, 0, 0, 0.2)";
      });
    },
    showPopup() {
      this.popup_action(true);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/index.scss";

.navbar {
  height: $toolbar-height;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 300px;
  z-index: 9;
  background-color: $main-bg;
  box-shadow: $box-shadow;
  border-radius: 999px;

  &-list {
    height: 100%;

    padding: 0 8px;
    position: relative;
    vertical-align: top;
    white-space: nowrap;
    @include flex(center, space-between);
  }

  &-item {
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      color: $main-color;
    }
  }
}
</style>
